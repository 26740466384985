
@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/GothamMedium.ttf");
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("./assets/fonts/GothamBold.ttf");
}

.font-weight-bold {
  font-family: "Gotham-Bold", sans-serif !important;
}

.font-normal {
  font-family: "Gotham", sans-serif !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-500 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}

.f-13 {
  font-size: 13px;
}

.f-12 {
  font-size: 12px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

input[type="radio"] {
  border: 0px;
  width: 15px;
  height: 15px;
}

.pointer {
  cursor: pointer;
}

.auth-input {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  outline: none;
  padding: 0.375rem 0.75rem;

  &:focus {
    border: 1px solid #303f9e;
  }
}
.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.text-red {
  color: #b42318;
}

:disabled {
  opacity: 0.5;
}

.form-group {
  position: relative;

  span.show-text {
    position: absolute;
    right: 1rem;
    top: 2.5rem;
    font-size: 0.8rem;
  }
}

.ok-button {
  width : 80%;
  background-color: #1d002e;
  color: white;
  padding: 10px 30px;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
}

.page-header {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #344054;
  margin-bottom: 1.5rem !important;
}

.btn-outline {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2px;
  color: #5c068c;
  width: auto;
  outline: none !important;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 40px;
  background-color: #ffffff;
}

.btn {
  &:disabled {
    cursor: not-allowed;
  }
  &__custom {
    &--purple {
      background: #5c068c;
      color: #ffffff;
      border: none;
      border-radius: 8px;
      outline: none !important;
      height: 40px;

      &:hover {
        color: #fff;
        background: lighten(#5c068c, 2);
      }
    }
    &--red {
      background-color: #d92d20;
      padding: 10px;
      color: #ffffff;
      border: none;
      border-radius: 8px;
      outline: none !important;
      height: 40px;
      width: 100%;

      &:hover {
        color: #fff;
        background: lighten(#d92d20, 2);
      }
    }
    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: #ffffff;
    }
  }
}

.form-input {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0rem 1rem;
  outline: none;
}

@media screen and (max-width: 767px) {
  .btn {
    &__custom {
      &--purple {
        height: 35px;
        font-size: 12px !important;
      }
    }
  }

  .btn-outline {
    font-size: 12px;
    height: 34px;
  }

  .page-header {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 1rem !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
